import React from 'react';
import PropTypes from 'prop-types';
import {useLocation, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Heading from '@frontend/ui-kit/Components/Heading';
import Separator from '@frontend/ui-kit/Components/Separator';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Link from '@frontend/ui-kit/Components/Link';
import Text, {TEXT_TYPES} from '@frontend/ui-kit/Components/Text';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import ImportCard from '../../ImportCard';
import {redirectTo} from '../../../../actions/general';
import {
    convertDateToTimeZone,
    getUTCDate,
    formatDate,
    getFileName,
    getFormattedIntervalToDuration,
    isEmpty,
    parseQuery,
    toCapitalize
} from '../../../../utils';
import {HOUSTON_URL, ROUTES, SIMPLE_ANSWERS} from '../../../../constants';
import './index.scss';

const EMPTY_FIELDS_CONDITIONS = ['empty_fields', 'not_empty_fields', 'not_empty_fields_or', 'empty_fields_or'];

const ALLOWABLE_CONDITIONS_FIELDS = ['allowable_condition', 'allowable_condition_or'];

const GeneralInformation = ({import_info: importInfo = {}}) => {
    const dispatch = useDispatch();
    const {partner, group_alias: groupAlias} = useParams();
    const {search} = useLocation();
    const {partner_name: partnerName, group_name: groupName} = parseQuery(search);
    const {import_config: importConfig = {}, additional_data: additionalData = {}, company_data: companyData = {}, created_at: createdAt, updated_at: updatedAt, ...rest} = importInfo;

    const getSimpleAnswer = value => value ? SIMPLE_ANSWERS.yes.toUpperCase() : SIMPLE_ANSWERS.no.toUpperCase();

    const getFile = url => <Link className='file-name' href={url} target='_blank'>{getFileName(url)}</Link>;

    const getTotalUploadTime = () => <span className='total-upload-time'>{getFormattedIntervalToDuration(createdAt, updatedAt)}</span>;

    const importInformation = [
        {title: 'Date', value: `${formatDate(convertDateToTimeZone(getUTCDate(createdAt)), 'MM/dd/yyyy H:mm')} CST`},
        {title: 'Config Type', value: additionalData.config_type},
        {title: 'Only New Users', value: getSimpleAnswer(rest.only_new_users)},
        {title: 'COBRA', value: getSimpleAnswer(rest.is_cobra)},
        {title: 'Total Upload Time', value: getTotalUploadTime()},
        {title: 'File Name', value: getFile(rest.files[0])},
        {title: 'Uploaded By', value: additionalData.created_by}
    ];

    const clientInformation = [
        {title: 'Status', value: companyData.group_launched ? 'Launched' : 'Not launched'},
        {title: 'Automation Status', value: companyData.automation_status ?? 'Not set'}
    ];

    const getFieldName = field => field.split('_').map(word => toCapitalize(word)).join(' ');

    const getConditions = (fields, valueFunc) => {
        return fields.map(field => isEmpty(importConfig[field]) ? null : <div>{getFieldName(field)}: <Text type={TEXT_TYPES.body}>{valueFunc(field)}</Text></div>)
            .filter(Boolean)
            .map(item => <p className='field-conditions'>{item}</p>);
    };

    const ImportConfigHighlights = [
        {title: 'Delete Plans If Empty', value: getSimpleAnswer(importConfig.delete_plans_if_empty)},
        {title: 'Termination If Not In File', value: getSimpleAnswer(importConfig.terminate_if_not_in_file)},
        {title: 'Inherit Core Plans', value: getSimpleAnswer(importConfig.inherit_core_plans)},
        {title: 'Inherit Ancillary Plans', value: getSimpleAnswer(importConfig.inherit_ancillary_plans)},
        {title: 'Inherit Member IDs', value: getSimpleAnswer(importConfig.inherit_member_ids_for_dependents)},
        {title: 'Delete Ancillary Plans If Empty', value: getSimpleAnswer(importConfig.delete_ancillary_plans_if_empty)},
        ...EMPTY_FIELDS_CONDITIONS.some(field => !isEmpty(importConfig[field]))
            ? [{title: 'Empty Fields Conditions', value: getConditions(EMPTY_FIELDS_CONDITIONS, field => importConfig[field].join(', '))}]
            : [],
        ...ALLOWABLE_CONDITIONS_FIELDS.some(field => !isEmpty(importConfig[field]))
            ? [{title: 'Allowable Conditions', value: getConditions(ALLOWABLE_CONDITIONS_FIELDS, field => JSON.stringify(importConfig[field]))}]
            : []
    ];

    const onGoToImportConfig = () => {
        const route = partner
            ? `${ROUTES.importsTpa}/${partner}?partner_name=${encodeURIComponent(partnerName || partner)}`
            : `${ROUTES.importsIndividual}/${groupAlias}/group_config?group_name=${encodeURIComponent(groupName)}#configuration`;
        dispatch(redirectTo(route));
    };

    return (
        <div className='general-information'>
            <Heading>Import Information</Heading>

            <Row className='mt-10' rowGap='md'>
                {importInformation.map(({title, value}) => (
                    <Column key={title} sm={3}>
                        <ImportCard title={title} value={value}/>
                    </Column>
                ))}
            </Row>

            <Separator/>

            <Heading>Client Information</Heading>

            <Row className='mt-10' rowGap='md'>
                {clientInformation.map(({title, value}) => (
                    <Column key={title} sm={3}>
                        <ImportCard title={title} value={value}/>
                    </Column>
                ))}
            </Row>

            <Separator/>

            <Heading data-testid='import-configuration-highlights'>
                Import Configuration Highlights
                <Button data-testid='view-import-config-button' onClick={onGoToImportConfig} type={BUTTON_TYPES.tertiary} className='ml-20'>View Import Config</Button>
                <Link data-testid='view-ancillary-config-button' href={`${HOUSTON_URL}/admin/voluntaryelectionimportconfig/`} isButton type={BUTTON_TYPES.tertiary} className='ml-28' target='_blank'>View Ancillary Config</Link>
            </Heading>

            <Row className='mt-10' rowGap='md'>
                {ImportConfigHighlights.map(({title, value}) => (
                    <Column key={title} sm={3}>
                        <ImportCard title={title} value={value}/>
                    </Column>
                ))}
            </Row>
        </div>
    );
};

GeneralInformation.propTypes = {
    import_info: PropTypes.shape({
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
        config_type: PropTypes.string,
        only_new_users: PropTypes.bool,
        is_cobra: PropTypes.bool,
        files: PropTypes.arrayOf(PropTypes.string),
        status: PropTypes.string,
        additional_data: PropTypes.shape({
            config_type: PropTypes.string,
            created_by: PropTypes.string
        }),
        import_config: PropTypes.shape({
            delete_plans_if_empty: PropTypes.bool,
            terminate_if_not_in_file: PropTypes.bool,
            inherit_core_plans: PropTypes.bool,
            inherit_ancillary_plans: PropTypes.bool,
            inherit_member_ids_for_dependents: PropTypes.bool,
            delete_ancillary_plans_if_empty: PropTypes.bool,
            not_empty_fields: PropTypes.arrayOf(PropTypes.string),
            not_empty_fields_or: PropTypes.arrayOf(PropTypes.string),
            empty_fields: PropTypes.arrayOf(PropTypes.string),
            empty_fields_or: PropTypes.arrayOf(PropTypes.string),
            allowable_condition: PropTypes.shape({}),
            allowable_condition_or: PropTypes.shape({})
        }),
        company_data: PropTypes.shape({
            group_launched: PropTypes.bool,
            automation_status: PropTypes.string
        })
    })
};

export {GeneralInformation as TestableGeneralInformation};
export default GeneralInformation;
