import {push, goBack, replace} from 'connected-react-router';
import {
    RECEIVE_ADMIN_PORTAL_BASIC_DATA,
    RECEIVE_BASIC_DATA,
    RECEIVE_COMPANIES_MAP,
    RECEIVE_MARKETING_PAGES,
    RECEIVE_TIMELINE,
    RESET_REDIRECT_TO_URL,
    SET_ACTIVE_COMPANY,
    SET_BROADCASTER_ONBOARDED,
    SET_REDIRECT_TO_URL,
    SET_STEP_ALIAS,
    SET_USER_ONBOARDED_DATA,
    SET_WORKSPACE,
    UPDATE_ACTIVE_COMPANY,
    UPDATE_TIMELINE_STEP
} from './actionTypes';
import apiServices from '../apiServices';
import {signOut} from './authorization';
import {setFormsDirtiness} from './shared';
import {getActiveCompany, getTimeline} from '../selectors/general';
import {getEnhancedTimelineSteps} from '../helpers';
import {equal} from '../utils';
import {HJ_CDN_BASE_URL, ROUTES, USER_ROLES, HTTP_CODE_NOT_FOUND} from '../constants';

const getEnhancedTimeline = timeline => {
    const isExisted = !!timeline.id;

    return {...timeline, isExisted};
};

const getEnhancedBasicData = ({activeCompany, profileInfo, timeline}) => {
    const enhancedProfileInfo = {...profileInfo, isSuperAdmin: equal(profileInfo.general_role, USER_ROLES.admin)};

    return {activeCompany, profileInfo: enhancedProfileInfo, timeline: getEnhancedTimeline(timeline)};
};

export const requestTimeline = async timelineId => {
    const {data, isSuccess} = await apiServices.getTimeline({timelineId});
    const timeline = isSuccess ? data : {steps: [], owner: {}};
    const enhancedTimeline = getEnhancedTimelineSteps(timeline);

    return {timeline: enhancedTimeline, isSuccess};
};

export const setActiveCompany = activeCompany => ({type: SET_ACTIVE_COMPANY, activeCompany});

const updateTimelineStep = () => ({type: UPDATE_TIMELINE_STEP});

const receiveBasicData = ({profileInfo, activeCompany, timeline}) => ({type: RECEIVE_BASIC_DATA, profileInfo, activeCompany, timeline});

const receiveAdminPortalBasicData = ({profileInfo}) => ({type: RECEIVE_ADMIN_PORTAL_BASIC_DATA, profileInfo});

const setUserOnboardedData = onboardedTypes => ({type: SET_USER_ONBOARDED_DATA, onboardedTypes});

const receiveCompaniesMap = companiesMap => ({type: RECEIVE_COMPANIES_MAP, companiesMap});

const receiveMarketingPages = marketingPages => ({type: RECEIVE_MARKETING_PAGES, marketingPages});

export const setRedirectToUrl = url => ({type: SET_REDIRECT_TO_URL, url});

export const resetRedirectToUrl = () => ({type: RESET_REDIRECT_TO_URL});

export const redirectTo = route => dispatch => dispatch(push(route));

export const redirectToBack = () => dispatch => dispatch(goBack());

export const replaceTo = route => dispatch => dispatch(replace(route));

export const requestJobs = params => async () => {
    const {data, isSuccess} = await apiServices.getJobs(params);
    const jobs = isSuccess ? data : [];

    return {jobs, isSuccess};
};

export const requestJob = jobId => async () => {
    const {data, isSuccess} = await apiServices.getJob(jobId);
    const job = isSuccess ? data : {};

    return {job, isSuccess};
};

export const requestJobSetting = params => async () => {
    const {data, isSuccess} = await apiServices.setJob(params);
    const job = isSuccess ? data : {};

    return {job, isSuccess};
};

export const requestFeatureFlag = (featureFlag, {companyAlias, userEmail} = {}) => async () => {
    const {data, isSuccess} = await apiServices.getFeatureFlag({featureFlag, companyAlias, userEmail});
    const {is_enabled: flag} = isSuccess ? data : {is_enabled: false};

    return {flag, isSuccess};
};

export const receiveTimeline = timeline => ({type: RECEIVE_TIMELINE, timeline: getEnhancedTimeline(timeline)});

export const setWorkspace = workspace => ({type: SET_WORKSPACE, workspace});

export const setStepAlias = stepAlias => ({type: SET_STEP_ALIAS, stepAlias});

export const updateActiveCompany = companyDetails => ({type: UPDATE_ACTIVE_COMPANY, companyDetails});

export const requestCompanyByAlias = companyAlias => async () => {
    const {data, isSuccess} = await apiServices.getCompanyByAlias({companyAlias});

    return {data, isSuccess};
};

export const requestCompanies = (query, params = {}) => async dispatch => {
    const {data, isSuccess} = await apiServices.getCompanies({query, ...params});
    const {data: companiesData, count} = data || {};
    const companies = isSuccess ? companiesData : [];
    const companiesMap = companies.reduce((acc, company) => ({...acc, [company.name]: company}), {});

    dispatch(receiveCompaniesMap(companiesMap));

    return {companies, count, isSuccess};
};

export const requestGroups = query => async () => {
    const {data, isSuccess} = await apiServices.getGroups(query);
    const {data: groups, count} = data || {};

    return {groups, count, isSuccess};
};

export const requestCompaniesByTPAPartner = ({offset, limit, partnerId, onlyNewLaunch, alias, aliasList} = {}) => async () => {
    const {data, isSuccess} = await apiServices.getGroupsByTPAPartner({offset, limit, partnerId, onlyNewLaunch, alias, aliasList});
    const groupsData = isSuccess ? data : {data: [], count: 0};

    return {groupsData, isSuccess};
};

export const requestTPAPartners = ({offset, limit, query} = {}) => async () => {
    const {data, isSuccess} = await apiServices.getTPAPartners({offset, limit, query});
    const {data: partners, count} = data || {};

    return {partners, count, isSuccess};
};

export const requestTPAPartner = partnerId => async () => {
    const {data, isSuccess} = await apiServices.getTPAPartner({id: partnerId});

    return {partner: data, isSuccess};
};

export const switchActiveCompany = companyId => async dispatch => {
    const {data: activeCompany} = await apiServices.setActiveCompany({companyId});
    const {data: profileInfo} = await apiServices.getProfileInfo({companyAlias: activeCompany.alias});
    const {timeline} = await requestTimeline(activeCompany?.timeline_id);

    const enhancedBasicData = getEnhancedBasicData({activeCompany, profileInfo, timeline});

    // HACK: hack for correct working of NavigationController. It helps to switch company without 'Leaving' popup(18.11.19, Oleh).
    dispatch(setFormsDirtiness({}));
    dispatch(receiveBasicData(enhancedBasicData));
    dispatch(redirectTo(ROUTES.root));
};

const requestCompanyState = async () => {
    const {data: {zendesk_redirect_uri: zendeskRedirectUri, ...data}, isSuccess, status} = await apiServices.getActiveCompany();

    return {data, isSuccess, status};
};

export const requestTimelineStepUpdating = (stepId, updatedFields) => async (dispatch, getState) => {
    const state = getState();
    const {activity_stage: activityStage} = getActiveCompany(state);
    const prevTimeline = getTimeline(state);

    const {data, isSuccess} = await apiServices.updateTimelineStep({stepId, updatedFields});
    const timeline = isSuccess ? getEnhancedTimelineSteps(data) : {};

    if (isSuccess) {
        dispatch(updateTimelineStep());
        dispatch(receiveTimeline(timeline));
    }

    if (prevTimeline.completed !== timeline.completed) {
        const {data: activeCompany} = await requestCompanyState();
        if (!activeCompany) {
            return {};
        }
        const {activity_stage: currActivityStage, timeline_id: timelineId} = activeCompany;

        dispatch(setActiveCompany(activeCompany));

        if (activityStage !== currActivityStage) {
            const {timeline} = await requestTimeline(timelineId);

            dispatch(receiveTimeline(timeline));
        }
    }

    return {isSuccess};
};

export const requestAdminPortalBasicData = () => async dispatch => {
    const {data: profileInfo, isSuccess} = await apiServices.getProfileInfo();

    if (!isSuccess) {
        return false;
    }

    const enhancedProfileInfo = {...profileInfo, isSuperAdmin: equal(profileInfo.general_role, USER_ROLES.admin)};

    dispatch(receiveAdminPortalBasicData({profileInfo: enhancedProfileInfo}));

    return {profileInfo: enhancedProfileInfo};
};

export const requestBasicData = companyId => async dispatch => {
    if (companyId) {
        const {isSuccess: isActiveCompanySettingSuccess} = await apiServices.setActiveCompany({companyId});

        if (!isActiveCompanySettingSuccess) {
            return dispatch(redirectTo(ROUTES.pageNotFound));
        }
    }

    const {data: activeCompany, isSuccess: isActiveCompanySuccess, status: activeCompanyStatus} = await requestCompanyState();
    if (equal(activeCompanyStatus, HTTP_CODE_NOT_FOUND)) {
        return dispatch(signOut());
    }
    if (!isActiveCompanySuccess || !activeCompany) {
        return false;
    }

    const {timeline_id: timelineId} = activeCompany;
    const {data: profileInfo, isSuccess: isProfileInfoSuccess} = await apiServices.getProfileInfo({companyAlias: activeCompany.alias});
    if (!isProfileInfoSuccess) {
        return false;
    }

    const {timeline} = await requestTimeline(timelineId);

    const enhancedBasicData = getEnhancedBasicData({activeCompany, profileInfo, timeline});
    dispatch(receiveBasicData(enhancedBasicData));

    return enhancedBasicData;
};

export const requestTermsAndConditionsSignUpdating = ({isSigned}) => async (dispatch, getState) => {
    const state = getState();
    const {alias: companyAlias} = getActiveCompany(state);

    const {data, isSuccess} = await apiServices.setTermsAndConditionsSign({companyAlias, isSigned});

    if (!isSuccess) {
        return {isSuccess, submissionErrors: data.messages};
    }

    dispatch(updateActiveCompany({should_tc_be_signed: !data.is_signed}));

    return {isSuccess, data};
};

export const setUserOnboarded = onboardedType => async dispatch => {
    const {data, isSuccess} = await apiServices.setUserOnboardedData({onboardedType});
    const onboardedTypes = isSuccess ? data : [];

    dispatch(setUserOnboardedData(onboardedTypes));
};

export const requestVersionHistoryList = ({versionHistoryId, versionHistoryType}) => async () => {
    const {data, isSuccess} = await apiServices.requestVersionHistoryList({versionHistoryId, versionHistoryType});

    return isSuccess ? data : [];
};

export const requestVersionHistoryDetails = versionId => async () => {
    const {data, isSuccess} = await apiServices.requestVersionHistoryDetails(versionId);

    return isSuccess ? data : {};
};

export const requestMarketingPages = () => async dispatch => {
    const {data, isSuccess} = await apiServices.getMarketingPages();
    const {pages = []} = isSuccess ? data : {};

    dispatch(receiveMarketingPages(pages));

    return {pages, isSuccess};
};

export const setBroadcasterOnboarded = () => ({type: SET_BROADCASTER_ONBOARDED});

export const requestBroadcasterOnboardedSetting = () => async dispatch => {
    const {isSuccess} = await apiServices.setBroadcasterOnboarded();

    if (!isSuccess) {
        return false;
    }

    dispatch(setBroadcasterOnboarded());
};

export const requestPublicFileUploading = file => async () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);

    const {data, isSuccess} = await apiServices.uploadPublicFile(formData);

    return {url: data?.url, isSuccess, errorMessage: data?.messages};
};

export const requestPrivateFileUploading = file => async () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', file.name);

    const {data, isSuccess} = await apiServices.setToThirdParty(`${HJ_CDN_BASE_URL}/external/upload-tmp`, formData, {headers: {'Content-Type': 'multipart/form-data'}});

    return {url: data?.data?.url, isSuccess, errorMessage: data?.message};
};

export const requestActiveCompany = () => requestCompanyState;

export const requestProcedures = ({query, ids, isLoader}) => async () => {
    const {data, isSuccess} = await apiServices.getProcedures({query, ids, isLoader});

    return {data: isSuccess ? data : [], isSuccess};
};

export const requestSpecialties = ({query, ids, isLoader}) => async () => {
    const {data, isSuccess} = await apiServices.getSpecialties({query, ids, isLoader});

    return {data: isSuccess ? data : [], isSuccess};
};
