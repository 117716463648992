import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import Alert, {ALERT_TYPES} from '@frontend/ui-kit/Components/Alert';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Column from '@frontend/ui-kit/Components/Column';
import Pagination from '@frontend/ui-kit/Components/Pagination';
import Row from '@frontend/ui-kit/Components/Row';
import Input from '@frontend/ui-kit/Components/Input';
import {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import TPAGroup from '../TPAGroup';
import {redirectTo, requestCompaniesByTPAPartner} from '../../../actions/general';
import {ROUTES} from '../../../constants';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import {parseQuery} from '../../../utils';
import './index.scss';

const GROUPS_PER_PAGE = 40;

const TPAGroupsAll = () => {
    const dispatch = useDispatch();
    const {partner} = useParams();
    const {search} = useLocation();
    const [inputSearch, setInputSearch] = useState('');
    const [groups, setGroups] = useState([]);
    const [groupsTotal, setGroupsTotal] = useState([]);
    const [remainingGroupsAmount, setRemainingGroupsAmount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const partnerDisplayName = parseQuery(search).partner_name || partner;

    const onGoToGroupDetails = (alias, title) => dispatch(redirectTo(`${ROUTES.importsTpa}/${partner}/${alias}?partner_name=${encodeURIComponent(partnerDisplayName)}&group_name=${encodeURIComponent(title)}`));

    const getTpaGroup = ({alias, title}) => {
        return (
            <Column sm={3} key={alias}>
                <TPAGroup name={alias} text={alias} onView={() => onGoToGroupDetails(alias, title)}/>
            </Column>
        );
    };

    const getCompanies = async (page = 0) => {
        const {groupsData} = await dispatch(requestCompaniesByTPAPartner({
            limit: GROUPS_PER_PAGE,
            offset: page * GROUPS_PER_PAGE,
            partnerId: partner,
            onlyNewLaunch: true,
            alias: inputSearch
        }));

        setGroups(groupsData.data);
        setGroupsTotal(groupsData.count);
    };

    const getCompaniesDebounced = useDebouncedCallback(getCompanies);

    const onPageChange = pageIndex => {
        setCurrentPage(pageIndex);
        getCompanies(pageIndex);
    };

    const onSearchChange = event => setInputSearch(event.target.value);

    useEffect(() => {
        (async () => {
            getCompanies();
            const {groupsData} = await dispatch(requestCompaniesByTPAPartner({partnerId: partner, onlyNewLaunch: false, limit: 1}));

            setRemainingGroupsAmount(groupsData.data.length);
        })();
    }, []);

    useEffect(() => {
        getCompaniesDebounced();
    }, [inputSearch]);

    const paginationProps = {
        unitCount: groupsTotal,
        pageIndex: currentPage,
        pageSize: GROUPS_PER_PAGE,
        pageCount: Math.ceil(groupsTotal / GROUPS_PER_PAGE),
        gotoPage: onPageChange
    };

    return (
        <div className='tpa-groups-all'>
            <Alert className='mb-20' type={ALERT_TYPES.warning} description={`Only New Launch groups are supported in Admin Portal at this time. Please see Houston to configure and import other remaining groups (${remainingGroupsAmount}).`}/>

            <Heading type={HEADING_TYPES['5']} className='mb-5' data-testid='partner-name'>{partnerDisplayName}</Heading>
            <Heading type={HEADING_TYPES['1']} className='mb-20'>All Groups</Heading>

            <Input value={inputSearch} onChange={onSearchChange} wrapperClassName='tpa-groups-all__search mb-20' icon={ICON_TYPES.search} placeholder='Search by company alias'/>

            <Row rowGap='md' className='mt-10'>
                {groups.map(getTpaGroup)}
            </Row>

            {groupsTotal > GROUPS_PER_PAGE && <Pagination {...paginationProps}/>}
        </div>
    );
};

export {TPAGroupsAll as TestableTPAGroupsAll, GROUPS_PER_PAGE};
export default React.memo(TPAGroupsAll);
